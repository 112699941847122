import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import PublishIcon from "@mui/icons-material/Publish";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";

function ErrorReport() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }

  const state = useSelector((state) => state);

  const [error, setError] = useState("");

  const navigate = useNavigate();

  function sendFeedback() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    body["error"] = error;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/submit_error_web", body, config)
      .then((res) => {
        createAlert("SUCCESS", "Success.", "Error reported");
        setTimeout(() => {
          navigate("/BluLens", { replace: true });
        }, 3000);
      })
      .catch((err) => {
        // createAlert("ERROR", "Error", err.response.data);
      });
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_manager) {
        return (
          <Fragment>
            <Sidebar />

            <div
              style={{
                margin: "100px 20px 20px 100px",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  marginTop: "-18px",
                }}
              >
                <Box fullWidth style={{ width: "40%" }}>
                  <h2>Error report For Elemzy</h2>
                  <div style={{ marginTop: "-10px" }}>
                    <h4>
                      Elemzy, being in the beta stage, may contain bugs or
                      errors. We value your feedback to enhance the user
                      experience
                    </h4>
                  </div>
                  <TextField
                    value={error}
                    style={{
                      marginTop: "2px",
                      marginBottom: "50px",
                      height: "40px",
                      marginBottom: "5px",
                    }}
                    onChange={(e) => setError(e.target.value)}
                    fullWidth
                    placeholder="Description"
                    variant="outlined"
                    multiline
                    rows={4}
                  />
                  <Box
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: 125,
                    }}
                  >
                    <Button
                      onClick={() => {
                        sendFeedback();
                      }}
                      style={{
                        width: 140,
                        height: 40,
                        borderRadius: 5,
                        backgroundColor: "#184e77",
                      }}
                    >
                      <h4
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: 20,
                        }}
                      >
                        SUBMIT
                      </h4>
                    </Button>
                  </Box>
                </Box>
              </div>
            </div>
          </Fragment>
        );
      } else {
        // createAlert("ERROR", "Permission denied.","Please Login as manager");

        return (
          <Fragment>
            <Navigate to="/login" />
          </Fragment>
        );
      }
    } else {
      return (
        <Fragment>
          <Navigate to="/login" />
        </Fragment>
      );
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default ErrorReport;
