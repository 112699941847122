import React, { useEffect, useState, Fragment, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  BarElement,
  RadialLinearScale,
  ArcElement,
  Legend,
  scales,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import { PolarArea } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";

import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import DeleteIcon from "@mui/icons-material/Delete";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import AddIcon from "@mui/icons-material/Add";
import PublishIcon from "@mui/icons-material/Publish";
import Drawer from "@mui/material/Drawer";
import Checkbox from "@mui/material/Checkbox";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import debounce from "lodash/debounce";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  BarElement,
  Filler,
  Legend,
  RadialLinearScale,
  ArcElement,
  zoomPlugin
);

function Dashboard() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [selectedDisease, setSelectedDisease] = useState("COVID");
  const [selectedDate, setSelectedDate] = useState("20/04/2024");
  const [selectedGroup, setSelectedGroup] = useState("OVERALL");
  const [showLables, setShowLables] = useState(true);
  const [dateList, setDateList] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState("Meadows");

  const [data, setData] = useState({});

  useEffect(() => {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    body["filename"] = "rootfile.xlsx";
    body = JSON.stringify(body);
    axios.post(API_URL + "/get_xl", body, config).then((res) => {
      // console.log(res.data);
      setData(res.data);
      setIsLoading(false);
      var datLst = [];
      for (var i = 0; i < res.data["Covid-19"].length; i++) {
        if (!datLst.includes(res.data["Covid-19"][i]["Date"])) {
          datLst.push(res.data["Covid-19"][i]["Date"]);
        }
      }
      datLst.reverse();
      datLst = datLst.filter((n) => n);
      setSelectedDisease("COVID");
      setDateList(datLst);
      setSelectedDate(datLst[0]);
      setIsLoadingData(false);
    });
  }, []);

  function handleTabChange(tab) {
    setIsLoadingData(true);
    if (tab == "COVID") {
      var datLst = [];
      for (var i = 0; i < data["Covid-19"].length; i++) {
        if (!datLst.includes(data["Covid-19"][i]["Date"])) {
          datLst.push(data["Covid-19"][i]["Date"]);
        }
      }
      setSelectedDisease("COVID");
      datLst = datLst.filter((n) => n);
      setDateList(datLst);
      setSelectedDate(datLst[0]);
      setIsLoadingData(false);
    } else if (tab == "DENGUE") {
      var datLst = [];
      for (var i = 0; i < data["Dengue"].length; i++) {
        if (!datLst.includes(data["Dengue"][i]["Last reading on"])) {
          datLst.push(data["Dengue"][i]["Last reading on"]);
        }
      }
      setSelectedDisease("DENGUE");
      if (selectedGroup == "OVERALL") {
        datLst.reverse();
      }
      datLst = datLst.filter((n) => n);
      setDateList(datLst);
      setSelectedDate(datLst[0]);
      setIsLoadingData(false);
    } else if (tab == "MALARIA") {
      var datLst = [];
      for (var i = 0; i < data["Malaria"].length; i++) {
        if (!datLst.includes(data["Malaria"][i]["Last reading on"])) {
          datLst.push(data["Malaria"][i]["Last reading on"]);
        }
      }
      setSelectedDisease("MALARIA");
      if (selectedGroup == "OVERALL") {
        datLst.reverse();
      }
      datLst = datLst.filter((n) => n);
      setDateList(datLst);
      setSelectedDate(datLst[0]);
      setIsLoadingData(false);
    } else if (tab == "NOISE") {
      var datLst = [];
      for (var i = 0; i < data["Noise"].length; i++) {
        if (!datLst.includes(data["Noise"][i]["Date"])) {
          datLst.push(data["Noise"][i]["Date"]);
        }
      }
      setSelectedDisease("NOISE");
      if (selectedGroup == "OVERALL") {
        datLst.reverse();
      }
      datLst = datLst.filter((n) => n);
      setDateList(datLst);
      setSelectedDate(datLst[0]);
      setIsLoadingData(false);
    } else if (tab == "AQI") {
      var datLst = [];
      for (var i = 0; i < data["AQI"].length; i++) {
        if (!datLst.includes(data["AQI"][i]["Date"])) {
          datLst.push(data["AQI"][i]["Date"]);
        }
      }
      setSelectedDisease("AQI");
      if (selectedGroup == "OVERALL") {
        datLst.reverse();
      }
      datLst = datLst.filter((n) => n);
      setDateList(datLst);
      setSelectedDate(datLst[0]);
      setIsLoadingData(false);
    } else if (tab == "WQI") {
      var datLst = [];
      for (var i = 0; i < data["WQI"].length; i++) {
        if (!datLst.includes(data["WQI"][i]["Date"])) {
          datLst.push(data["WQI"][i]["Date"]);
        }
      }
      setSelectedDisease("WQI");
      if (selectedGroup == "OVERALL") {
        datLst.reverse();
      }
      datLst = datLst.filter((n) => n);
      setDateList(datLst);
      setSelectedDate(datLst[0]);
      setIsLoadingData(false);
    }
  }

  function getDates() {
    var dats = dateList.map((dat, index) => (
      <MenuItem value={dat}>{moment(dat).format("DD-MM-YYYY")}</MenuItem>
    ));

    return dats;
  }

  const chartRef = React.useRef(null);

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  function getContent() {
    if (selectedDisease == "COVID") {
      var options = {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
          legend: {
            position: "bottom",
            marginBottom: "30px",
          },
          title: {
            display: true,
            position: "bottom",
            text:
              "COVID-19 data on " + moment(selectedDate).format("DD-MM-YYYY"),
          },
        },
      };

      function getCovidData() {
        var datList = [];
        for (var i = 0; i < data["Covid-19"].length; i++) {
          if (data["Covid-19"][i]["Location"] == selectedPlace) {
            datList.push(data["Covid-19"][i]["Viral load"]);
          }
        }
        return datList;
      }

      const labels = dateList.map((dat) => moment(dat).format("DD-MM-YYYY"));

      const dataGraph = {
        labels,
        datasets: [
          {
            fill: false,
            label: "Viral Load",
            data: getCovidData(),
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(53, 162, 235)",
            },
          },
        ],
      };
      return (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: 20,
          }}
        >
          <Line
            style={{ width: "75vw" }}
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "100%",
            }}
          >
            <div
              style={{
                color: "black",
                fontFamily: "Lato-Regular",
                fontSize: "18px",
                marginTop: "15px",
              }}
            >
              Select Place
            </div>
            <Select
              value={selectedPlace}
              onChange={(e) => setSelectedPlace(e.target.value)}
              style={{
                marginTop: "5px",
                height: "40px",
                marginBottom: "5px",
                backgroundColor: "white",
                // color:"white"
              }}
              fullWidth
              variant={"outlined"}
            >
              <MenuItem value={"Meadows"}>{"Meadows"}</MenuItem>
              <MenuItem value={"North Park"}>{"North Park"}</MenuItem>
              <MenuItem value={"Elysium"}>{"Elysium"}</MenuItem>
              <MenuItem value={"Aangan"}>{"Aangan"}</MenuItem>
              <MenuItem value={"Water lily"}>{"Water lily"}</MenuItem>
              <MenuItem value={"Workers colony"}>{"Workers colony"}</MenuItem>
            </Select>
            <Button onClick={() => setShowLables(!showLables)}>
              {showLables ? "Hide" : "Show"} Labels
            </Button>
            <Button onClick={handleResetZoom}>Reset Zoom</Button>
          </div>
        </div>
      );
    } else if (selectedDisease == "DENGUE") {
      var options = {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Dengue data on " + moment(selectedDate).format("DD-MM-YYYY"),
          },
        },
      };

      const labels = dateList.map((dat) => moment(dat).format("DD-MM-YYYY"));

      function getNoOfContainers() {
        var datList = [];
        for (var i = 0; i < data["Dengue"].length; i++) {
          if (data["Dengue"][i]["Location"] == selectedPlace) {
            datList.push(data["Dengue"][i]["Number of Containers"]);
          }
        }
        return datList;
      }
      function getPositiveContainers() {
        var datList = [];
        for (var i = 0; i < data["Dengue"].length; i++) {
          if (data["Dengue"][i]["Location"] == selectedPlace) {
            datList.push(data["Dengue"][i]["Positive Containers"]);
          }
        }
        return datList;
      }
      function getNoOfEggs() {
        var datList = [];
        for (var i = 0; i < data["Dengue"].length; i++) {
          if (data["Dengue"][i]["Location"] == selectedPlace) {
            datList.push(data["Dengue"][i]["Average number of eggs"]);
          }
        }
        return datList;
      }
      function getNumberOfLarvae() {
        var datList = [];
        for (var i = 0; i < data["Dengue"].length; i++) {
          if (data["Dengue"][i]["Location"] == selectedPlace) {
            datList.push(data["Dengue"][i]["Average number of larvae"]);
          }
        }
        return datList;
      }

      const dataGraph = {
        labels,
        datasets: [
          {
            fill: false,
            label: "Number of Containers",
            data: getNoOfContainers(),
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(53, 162, 235)",
            },
          },
          {
            fill: false,
            label: "Average number of eggs",
            data: getNoOfEggs(),
            borderColor: "rgb(230, 85, 13)",
            backgroundColor: "rgba(230, 85, 13, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(230, 85, 13)",
            },
          },
          {
            fill: false,
            label: "Positive Containers",
            data: getPositiveContainers(),
            borderColor: "rgb(222, 45, 38)",
            backgroundColor: "rgba(222, 45, 38, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(222, 45, 38)",
            },
          },
          {
            fill: false,
            label: "Average number of larvae",
            data: getNumberOfLarvae(),
            borderColor: "rgb(99, 99, 99)",
            backgroundColor: "rgba(99, 99, 99, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(99, 99, 99)",
            },
          },
        ],
      };
      return (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Line
            style={{ width: "75vw" }}
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "100%",
            }}
          >
            <div
              style={{
                color: "black",
                fontFamily: "Lato-Regular",
                fontSize: "18px",
                marginTop: "15px",
              }}
            >
              Select Place
            </div>
            <Select
              value={selectedPlace}
              onChange={(e) => setSelectedPlace(e.target.value)}
              style={{
                marginTop: "5px",
                height: "40px",
                marginBottom: "5px",
                backgroundColor: "white",
                // color:"white"
              }}
              fullWidth
              variant={"outlined"}
            >
              <MenuItem value={"Meadows"}>{"Meadows"}</MenuItem>
              <MenuItem value={"North Park"}>{"North Park"}</MenuItem>
              <MenuItem value={"Elysium"}>{"Elysium"}</MenuItem>
              <MenuItem value={"Aangan"}>{"Aangan"}</MenuItem>
              <MenuItem value={"Water lily"}>{"Water lily"}</MenuItem>
              <MenuItem value={"Workers colony"}>{"Workers colony"}</MenuItem>
              <MenuItem value={"Shopping centre"}>{"Shopping centre"}</MenuItem>
            </Select>
            <Button onClick={() => setShowLables(!showLables)}>
              {showLables ? "Hide" : "Show"} Labels
            </Button>
            <Button onClick={handleResetZoom}>Reset Zoom</Button>
          </div>
        </div>
      );
    } else if (selectedDisease == "MALARIA") {
      var options = {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text:
              "Malaria data on " + moment(selectedDate).format("DD-MM-YYYY"),
          },
        },
      };

      const labels = dateList.map((dat) => moment(dat).format("DD-MM-YYYY"));

      function getNoOfContainers() {
        var datList = [];
        for (var i = 0; i < data["Malaria"].length; i++) {
          if (data["Malaria"][i]["Location"] == selectedPlace) {
            datList.push(data["Malaria"][i]["Number of Containers"]);
          }
        }
        return datList;
      }
      function getPositiveContainers() {
        var datList = [];
        for (var i = 0; i < data["Malaria"].length; i++) {
          if (data["Malaria"][i]["Location"] == selectedPlace) {
            datList.push(data["Malaria"][i]["Positive Containers"]);
          }
        }
        return datList;
      }

      function getNumberOfLarvae() {
        var datList = [];
        for (var i = 0; i < data["Malaria"].length; i++) {
          if (data["Malaria"][i]["Location"] == selectedPlace) {
            datList.push(data["Malaria"][i]["Average number of larvae"]);
          }
        }
        return datList;
      }

      const dataGraph = {
        labels,
        datasets: [
          {
            fill: false,
            label: "Number of Containers",
            data: getNoOfContainers(),
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(53, 162, 235)",
            },
          },

          {
            fill: true,
            label: "Positive Containers",
            data: getPositiveContainers(),
            borderColor: "rgb(222, 45, 38)",
            backgroundColor: "rgba(222, 45, 38, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(222, 45, 38)",
            },
          },
          {
            fill: false,
            label: "Average number of larvae",
            data: getNumberOfLarvae(),
            borderColor: "rgb(99, 99, 99)",
            backgroundColor: "rgba(99, 99, 99, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(99, 99, 99)",
            },
          },
        ],
      };
      return (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Line
            style={{ width: "75vw" }}
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "100%",
            }}
          >
            <div
              style={{
                color: "black",
                fontFamily: "Lato-Regular",
                fontSize: "18px",
                marginTop: "15px",
              }}
            >
              Select Place
            </div>
            <Select
              value={selectedPlace}
              onChange={(e) => setSelectedPlace(e.target.value)}
              style={{
                marginTop: "5px",
                height: "40px",
                marginBottom: "5px",
                backgroundColor: "white",
                // color:"white"
              }}
              fullWidth
              variant={"outlined"}
            >
              <MenuItem value={"Meadows"}>{"Meadows"}</MenuItem>
              <MenuItem value={"North Park"}>{"North Park"}</MenuItem>
              <MenuItem value={"Elysium"}>{"Elysium"}</MenuItem>
              <MenuItem value={"Aangan"}>{"Aangan"}</MenuItem>
              <MenuItem value={"Water lily"}>{"Water lily"}</MenuItem>
              <MenuItem value={"Workers colony"}>{"Workers colony"}</MenuItem>
              <MenuItem value={"Shopping centre"}>{"Shopping centre"}</MenuItem>
            </Select>
            <Button onClick={() => setShowLables(!showLables)}>
              {showLables ? "Hide" : "Show"} Labels
            </Button>
            <Button onClick={handleResetZoom}>Reset Zoom</Button>
          </div>
        </div>
      );
    } else if (selectedDisease == "NOISE") {
      var options = {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Noise data on " + moment(selectedDate).format("DD-MM-YYYY"),
          },
        },
      };

      const labels = dateList.map((dat) => moment(dat).format("DD-MM-YYYY"));

      function getCovidData() {
        var datList = [];
        for (var i = 0; i < data["Noise"].length; i++) {
          if (data["Noise"][i]["Location"] == selectedPlace) {
            datList.push(data["Noise"][i]["Decibles"]);
          }
        }
        return datList;
      }

      const dataGraph = {
        labels,
        datasets: [
          {
            fill: true,
            label: "Noise in Decibels",
            data: getCovidData(),
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(53, 162, 235)",
            },
          },
        ],
      };
      return (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Line
            style={{ width: "75vw" }}
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "100%",
            }}
          >
            <div
              style={{
                color: "black",
                fontFamily: "Lato-Regular",
                fontSize: "18px",
                marginTop: "15px",
              }}
            >
              Select Place
            </div>
            <Select
              value={selectedPlace}
              onChange={(e) => setSelectedPlace(e.target.value)}
              style={{
                marginTop: "5px",
                height: "40px",
                marginBottom: "5px",
                backgroundColor: "white",
                // color:"white"
              }}
              fullWidth
              variant={"outlined"}
            >
              <MenuItem value={"Meadows"}>{"Meadows"}</MenuItem>
              <MenuItem value={"North Park"}>{"North Park"}</MenuItem>
              <MenuItem value={"Elysium"}>{"Elysium"}</MenuItem>
              <MenuItem value={"Aangan"}>{"Aangan"}</MenuItem>
              <MenuItem value={"Water lily"}>{"Water lily"}</MenuItem>
              <MenuItem value={"Workers colony"}>{"Workers colony"}</MenuItem>
              <MenuItem value={"Shopping centre"}>{"Shopping centre"}</MenuItem>
            </Select>
            <Button onClick={() => setShowLables(!showLables)}>
              {showLables ? "Hide" : "Show"} Labels
            </Button>
            <Button onClick={handleResetZoom}>Reset Zoom</Button>
          </div>
        </div>
      );
    } else if (selectedDisease == "TYPHOID") {
      var options = {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Typhoid data",
          },
        },
      };

      var tempData = Array.from(data["Typhoid-Cholera"]);
      tempData.splice(0, 1);

      const labels = tempData.map((temp) =>
        moment(temp["Typhoid"]).format("DD-MM-YYYY")
      );

      const dataGraph = {
        labels,
        datasets: [
          {
            fill: true,
            label: "Salmonella Presence",
            data: tempData.map((temp) =>
              temp["Unnamed: 1"] == "Absent" ? 0 : 1
            ),
            borderColor: "rgba(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "255, 99, 132",
            },
          },
        ],
      };
      return (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Bar
            style={{ width: "75vw" }}
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
        </div>
      );
    } else if (selectedDisease == "CHOLERA") {
      var options = {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Cholera data",
          },
        },
      };

      var tempData2 = Array.from(data["Typhoid-Cholera"]);
      tempData2.splice(0, 1);

      const labels = tempData2.map((temp) =>
        moment(temp["Cholera"]).format("DD-MM-YYYY")
      );

      const dataGraph = {
        labels,
        datasets: [
          {
            fill: true,
            label: "Vibro Cholerae Presence",
            data: tempData2.map((temp, index) => {
              return temp["Unnamed: 4"] == "Absent" ? 0 : 1;
            }),
            borderColor: "rgba(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(255, 99, 132)",
            },
          },
        ],
      };
      return (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Bar
            style={{ width: "75vw" }}
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
        </div>
      );
    } else if (selectedDisease == "AQI") {
      var options = {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "AQI data on " + moment(selectedDate).format("DD-MM-YYYY"),
          },
        },
      };

      const labels = dateList.map((dat) => moment(dat).format("DD-MM-YYYY"));

      function get1() {
        var datList = [];
        for (var i = 0; i < data["AQI"].length; i++) {
          if (data["AQI"][i]["Cluster"] == selectedPlace) {
            if (data["AQI"][i]["PM10"] == null) {
              datList.push(0);
            } else {
              datList.push(data["AQI"][i]["PM10"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get2() {
        var datList = [];
        for (var i = 0; i < data["AQI"].length; i++) {
          if (data["AQI"][i]["Cluster"] == selectedPlace) {
            if (data["AQI"][i]["PM2.5"] == null) {
              datList.push(0);
            } else {
              datList.push(data["AQI"][i]["PM2.5"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get3() {
        var datList = [];
        for (var i = 0; i < data["AQI"].length; i++) {
          if (data["AQI"][i]["Cluster"] == selectedPlace) {
            if (data["AQI"][i]["SO2"] == null) {
              datList.push(0);
            } else {
              datList.push(data["AQI"][i]["SO2"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get4() {
        var datList = [];
        for (var i = 0; i < data["AQI"].length; i++) {
          if (data["AQI"][i]["Cluster"] == selectedPlace) {
            if (data["AQI"][i]["NO2"] == null) {
              datList.push(0);
            } else {
              datList.push(data["AQI"][i]["NO2"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get5() {
        var datList = [];
        for (var i = 0; i < data["AQI"].length; i++) {
          if (data["AQI"][i]["Cluster"] == selectedPlace) {
            if (data["AQI"][i]["CO"] == null) {
              datList.push(0);
            } else {
              datList.push(data["AQI"][i]["CO"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get6() {
        var datList = [];
        for (var i = 0; i < data["AQI"].length; i++) {
          if (data["AQI"][i]["Cluster"] == selectedPlace) {
            if (data["AQI"][i]["O3"] == null) {
              datList.push(0);
            } else {
              datList.push(data["AQI"][i]["O3"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get7() {
        var datList = [];
        for (var i = 0; i < data["AQI"].length; i++) {
          if (data["AQI"][i]["Cluster"] == selectedPlace) {
            if (data["AQI"][i]["NH3"] == null) {
              datList.push(0);
            } else {
              datList.push(data["AQI"][i]["NH3"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get8() {
        var datList = [];
        for (var i = 0; i < data["AQI"].length; i++) {
          if (data["AQI"][i]["Cluster"] == selectedPlace) {
            if (data["AQI"][i]["AQI"] == null) {
              datList.push(0);
            } else {
              datList.push(data["AQI"][i]["AQI"].toFixed(2));
            }
          }
        }
        return datList;
      }

      const dataGraph = {
        labels,
        datasets: [
          {
            fill: false,
            label: "PM10",
            data: get1(),
            borderColor: "rgba(53, 162, 235, 0.5)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(53, 162, 235, 0.5)",
            },
          },
          {
            fill: false,
            label: "PM2.5",
            data: get2(),
            borderColor: "rgba(230, 85, 13, 0.5)",
            backgroundColor: "rgba(230, 85, 13, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(230, 85, 13, 0.5)",
            },
          },
          {
            fill: false,
            label: "SO2",
            data: get3(),
            borderColor: "rgba(222, 45, 38, 0.5)",
            backgroundColor: "rgba(222, 45, 38, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(222, 45, 38, 0.5)",
            },
          },
          {
            fill: false,
            label: "NO2",
            data: get4(),
            borderColor: "rgba(99, 99, 99, 0.5)",
            backgroundColor: "rgba(99, 99, 99, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(99, 99, 99, 0.5)",
            },
          },
          {
            fill: false,
            label: "CO",
            data: get5(),
            borderColor: "rgba(136, 86, 167, 0.5)",
            backgroundColor: "rgba(136, 86, 167,0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(136, 86, 167,0.5)",
            },
          },
          {
            fill: false,
            label: "O3",
            data: get6(),
            borderColor: "rgba(28, 144, 153, 0.5)",
            backgroundColor: "rgba(28, 144, 153,0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(28, 144, 153,0.5)",
            },
          },
          {
            fill: false,
            label: "NH3",
            data: get7(),
            borderColor: "rgba(44, 162, 95, 0.5)",
            backgroundColor: "rgba(44, 162, 95,0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(44, 162, 95, 0.5)",
            },
          },
          {
            fill: false,
            label: "AQI",
            data: get8(),
            borderColor: "rgb(12, 44, 132)",
            backgroundColor: "rgba(12, 44, 132,0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(12, 44, 132)",
            },
          },
        ],
      };
      return (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Line
            style={{ width: "75vw" }}
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "100%",
            }}
          >
            <div
              style={{
                color: "black",
                fontFamily: "Lato-Regular",
                fontSize: "18px",
                marginTop: "15px",
              }}
            >
              Select Place
            </div>
            <Select
              value={selectedPlace}
              onChange={(e) => setSelectedPlace(e.target.value)}
              style={{
                marginTop: "5px",
                height: "40px",
                marginBottom: "5px",
                backgroundColor: "white",
                // color:"white"
              }}
              fullWidth
              variant={"outlined"}
            >
              <MenuItem value={"Meadows"}>{"Meadows"}</MenuItem>
              <MenuItem value={"North Park"}>{"North Park"}</MenuItem>
              <MenuItem value={"Elysium"}>{"Elysium"}</MenuItem>
              <MenuItem value={"Aangan"}>{"Aangan"}</MenuItem>
              <MenuItem value={"Water lily"}>{"Water lily"}</MenuItem>
              <MenuItem value={"Workers colony"}>{"Workers colony"}</MenuItem>
              <MenuItem value={"Shopping centre"}>{"Shopping centre"}</MenuItem>
            </Select>
            <Button onClick={() => setShowLables(!showLables)}>
              {showLables ? "Hide" : "Show"} Labels
            </Button>
            <Button onClick={handleResetZoom}>Reset Zoom</Button>
          </div>
        </div>
      );
    } else if (selectedDisease == "WQI") {
      var options = {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "WQI data on " + moment(selectedDate).format("DD-MM-YYYY"),
          },
        },
      };

      function get1() {
        var datList = [];
        for (var i = 0; i < data["WQI"].length; i++) {
          if (data["WQI"][i]["Parameters"] == selectedPlace) {
            if (data["WQI"][i]["Si"] == null) {
              datList.push(0);
            } else {
              datList.push(data["WQI"][i]["Si"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get2() {
        var datList = [];
        for (var i = 0; i < data["WQI"].length; i++) {
          if (data["WQI"][i]["Parameters"] == selectedPlace) {
            if (data["WQI"][i]["Si"] == null) {
              datList.push(0);
            } else {
              datList.push(data["WQI"][i]["Vi"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get3() {
        var datList = [];
        for (var i = 0; i < data["WQI"].length; i++) {
          if (data["WQI"][i]["Parameters"] == selectedPlace) {
            if (data["WQI"][i]["Si"] == null) {
              datList.push(0);
            } else {
              datList.push(data["WQI"][i]["1/Si"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get4() {
        var datList = [];
        for (var i = 0; i < data["WQI"].length; i++) {
          if (data["WQI"][i]["Parameters"] == selectedPlace) {
            if (data["WQI"][i]["Si"] == null) {
              datList.push(0);
            } else {
              datList.push(data["WQI"][i]["wi"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get5() {
        var datList = [];
        for (var i = 0; i < data["WQI"].length; i++) {
          if (data["WQI"][i]["Parameters"] == selectedPlace) {
            if (data["WQI"][i]["Si"] == null) {
              datList.push(0);
            } else {
              datList.push(data["WQI"][i]["Wi"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get6() {
        var datList = [];
        for (var i = 0; i < data["WQI"].length; i++) {
          if (data["WQI"][i]["Parameters"] == selectedPlace) {
            if (data["WQI"][i]["Si"] == null) {
              datList.push(0);
            } else {
              datList.push(data["WQI"][i]["Qi"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get7() {
        var datList = [];
        for (var i = 0; i < data["WQI"].length; i++) {
          if (data["WQI"][i]["Parameters"] == selectedPlace) {
            if (data["WQI"][i]["Si"] == null) {
              datList.push(0);
            } else {
              datList.push(data["WQI"][i]["A"].toFixed(2));
            }
          }
        }
        return datList;
      }
      const labels = dateList.map((dat) => moment(dat).format("DD-MM-YYYY"));

      const dataGraph = {
        labels,
        datasets: [
          {
            fill: false,
            label: "Si",
            data: get1(),
            borderColor: "rgba(53, 162, 235, 0.5)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(53, 162, 235, 0.5)",
            },
          },
          {
            fill: false,
            label: "Vi",
            data: get2(),
            borderColor: "rgba(230, 85, 13, 0.5)",
            backgroundColor: "rgba(230, 85, 13, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(230, 85, 13, 0.5)",
            },
          },
          {
            fill: false,
            label: "1/Si",
            data: get3(),
            borderColor: "rgba(222, 45, 38, 0.5)",
            backgroundColor: "rgba(222, 45, 38, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(222, 45, 38, 0.5)",
            },
          },
          {
            fill: false,
            label: "wi",
            data: get4(),
            borderColor: "rgba(99, 99, 99, 0.5)",
            backgroundColor: "rgba(99, 99, 99, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(99, 99, 99, 0.5)",
            },
          },
          {
            fill: false,
            label: "Wi",
            data: get5(),
            borderColor: "rgba(136, 86, 167, 0.5)",
            backgroundColor: "rgba(136, 86, 167,0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(136, 86, 167,0.5)",
            },
          },
          {
            fill: false,
            label: "Qi",
            data: get6(),
            borderColor: "rgba(28, 144, 153, 0.5)",
            backgroundColor: "rgba(28, 144, 153,0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(28, 144, 153,0.5)",
            },
          },
          {
            fill: false,
            label: "A",
            data: get7(),
            borderColor: "rgba(44, 162, 95, 0.5)",
            backgroundColor: "rgba(44, 162, 95,0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(44, 162, 95,0.5)",
            },
          },
        ],
      };
      return (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Line
            style={{ width: "75vw" }}
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "100%",
            }}
          >
            <div
              style={{
                color: "black",
                fontFamily: "Lato-Regular",
                fontSize: "18px",
                marginTop: "15px",
              }}
            >
              Select Parameter
            </div>
            <Select
              value={selectedPlace}
              onChange={(e) => setSelectedPlace(e.target.value)}
              style={{
                marginTop: "5px",
                height: "40px",
                marginBottom: "5px",
                backgroundColor: "white",
                // color:"white"
              }}
              fullWidth
              variant={"outlined"}
            >
              <MenuItem value={"pH value"}>{"pH value"}</MenuItem>
              <MenuItem value={"Electrical Conductivity (EC)"}>
                {"Electrical Conductivity (EC)"}
              </MenuItem>
              <MenuItem value={"Total Dissolved Solids (TDS)"}>
                {"Total Dissolved Solids (TDS)"}
              </MenuItem>
              <MenuItem value={"Turbidity"}>{"Turbidity"}</MenuItem>
              <MenuItem value={"Total Alkalinity"}>
                {"Total Alkalinity"}
              </MenuItem>
              <MenuItem value={"Total Hardness"}>{"Total Hardness"}</MenuItem>

              <MenuItem value={"Sodium"}>{"Sodium"}</MenuItem>
              <MenuItem value={"Calcium"}>{"Calcium"}</MenuItem>
              <MenuItem value={"Magnesium"}>{"Magnesium"}</MenuItem>
              <MenuItem value={"Sulphate"}>{"Sulphate"}</MenuItem>
              <MenuItem value={"Chloride"}>{"Chloride"}</MenuItem>
              <MenuItem value={"BOD"}>{"BOD"}</MenuItem>
              <MenuItem value={"Silica"}>{"Silica"}</MenuItem>
              <MenuItem value={"Nitrate"}>{"Nitrate"}</MenuItem>
              <MenuItem value={"Arsenic"}>{"Arsenic"}</MenuItem>
              <MenuItem value={"Iron"}>{"Iron"}</MenuItem>
              <MenuItem value={"Lead"}>{"Lead"}</MenuItem>
              <MenuItem value={"Mercury"}>{"Mercury"}</MenuItem>
              <MenuItem value={"Cadmium"}>{"Cadmium"}</MenuItem>
              <MenuItem value={"Total coliform (1 Present,0 Absent)"}>
                {"Total coliform (1 Present,0 Absent)"}
              </MenuItem>
              <MenuItem value={"1 Present,0 Absent"}>
                {"1 Present,0 Absent"}
              </MenuItem>
            </Select>
            <Button onClick={() => setShowLables(!showLables)}>
              {showLables ? "Hide" : "Show"} Labels
            </Button>
            <Button onClick={handleResetZoom}>Reset Zoom</Button>
          </div>
        </div>
      );
    }
  }

  function getOverallContent() {
    if (selectedDisease == "COVID") {
      var options = {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text:
              "COVID-19 data on " + moment(selectedDate).format("DD-MM-YYYY"),
          },
        },
      };

      const labels = [
        "Meadows",
        "North Park",
        "Elysium",
        "Aangan",
        "Water lily",
        "Workers colony",
      ];

      function getCovidData() {
        var datList = [];
        for (var i = 0; i < data["Covid-19"].length; i++) {
          if (data["Covid-19"][i]["Date"] == selectedDate) {
            datList.push(data["Covid-19"][i]["Viral load"]);
          }
        }
        return datList;
      }

      const dataGraph = {
        labels,
        datasets: [
          {
            fill: true,
            label: "Viral Load",
            data: getCovidData(),
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(53, 162, 235)",
            },
          },
        ],
      };
      return (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Bar
            style={{ width: "75vw" }}
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
        </div>
      );
    } else if (selectedDisease == "DENGUE") {
      var options = {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Dengue data on " + moment(selectedDate).format("DD-MM-YYYY"),
          },
        },
      };

      const labels = [
        "Meadows",
        "North Park",
        "Elysium",
        "Water lily",
        "Aangan",
        "Workers colony",
        "Shopping centre",
      ];

      function getNoOfContainers() {
        var datList = [];
        for (var i = 0; i < data["Dengue"].length; i++) {
          if (data["Dengue"][i]["Last reading on"] == selectedDate) {
            datList.push(data["Dengue"][i]["Number of Containers"]);
          }
        }
        return datList;
      }
      function getPositiveContainers() {
        var datList = [];
        for (var i = 0; i < data["Dengue"].length; i++) {
          if (data["Dengue"][i]["Last reading on"] == selectedDate) {
            datList.push(data["Dengue"][i]["Positive Containers"]);
          }
        }
        return datList;
      }
      function getNoOfEggs() {
        var datList = [];
        for (var i = 0; i < data["Dengue"].length; i++) {
          if (data["Dengue"][i]["Last reading on"] == selectedDate) {
            datList.push(data["Dengue"][i]["Average number of eggs"]);
          }
        }
        return datList;
      }
      function getNumberOfLarvae() {
        var datList = [];
        for (var i = 0; i < data["Dengue"].length; i++) {
          if (data["Dengue"][i]["Last reading on"] == selectedDate) {
            datList.push(data["Dengue"][i]["Average number of larvae"]);
          }
        }
        return datList;
      }

      const dataGraph = {
        labels,
        datasets: [
          {
            fill: false,
            label: "Number of Containers",
            data: getNoOfContainers(),
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(53, 162, 235)",
            },
          },
          {
            fill: false,
            label: "Average number of eggs",
            data: getNoOfEggs(),
            borderColor: "rgb(230, 85, 13)",
            backgroundColor: "rgba(230, 85, 13, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(230, 85, 13)",
            },
          },
          {
            fill: false,
            label: "Positive Containers",
            data: getPositiveContainers(),
            borderColor: "rgb(222, 45, 38)",
            backgroundColor: "rgba(222, 45, 38, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(222, 45, 38)",
            },
          },
          {
            fill: false,
            label: "Average number of larvae",
            data: getNumberOfLarvae(),
            borderColor: "rgb(99, 99, 99)",
            backgroundColor: "rgba(99, 99, 99, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(99, 99, 99)",
            },
          },
        ],
      };
      return (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Bar
            style={{ width: "75vw" }}
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
        </div>
      );
    } else if (selectedDisease == "MALARIA") {
      var options = {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text:
              "Malaria data on " + moment(selectedDate).format("DD-MM-YYYY"),
          },
        },
      };

      const labels = [
        "Meadows",
        "North Park",
        "Elysium",
        "Water lily",
        "Aangan",
        "Workers colony",
        "Shopping centre",
      ];

      function getNoOfContainers() {
        var datList = [];
        for (var i = 0; i < data["Malaria"].length; i++) {
          if (data["Malaria"][i]["Last reading on"] == selectedDate) {
            datList.push(data["Malaria"][i]["Number of Containers"]);
          }
        }
        return datList;
      }
      function getPositiveContainers() {
        var datList = [];
        for (var i = 0; i < data["Malaria"].length; i++) {
          if (data["Malaria"][i]["Last reading on"] == selectedDate) {
            datList.push(data["Malaria"][i]["Positive Containers"]);
          }
        }
        return datList;
      }

      function getNumberOfLarvae() {
        var datList = [];
        for (var i = 0; i < data["Malaria"].length; i++) {
          if (data["Malaria"][i]["Last reading on"] == selectedDate) {
            datList.push(data["Malaria"][i]["Average number of larvae"]);
          }
        }
        return datList;
      }

      const dataGraph = {
        labels,
        datasets: [
          {
            fill: false,
            label: "Number of Containers",
            data: getNoOfContainers(),
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(53, 162, 235)",
            },
          },

          {
            fill: true,
            label: "Positive Containers",
            data: getPositiveContainers(),
            borderColor: "rgb(222, 45, 38)",
            backgroundColor: "rgba(222, 45, 38, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(222, 45, 38)",
            },
          },
          {
            fill: false,
            label: "Average number of larvae",
            data: getNumberOfLarvae(),
            borderColor: "rgb(99, 99, 99)",
            backgroundColor: "rgba(99, 99, 99, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(99, 99, 99)",
            },
          },
        ],
      };
      return (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Bar
            style={{ width: "75vw" }}
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
        </div>
      );
    } else if (selectedDisease == "NOISE") {
      var options = {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Noise data on " + moment(selectedDate).format("DD-MM-YYYY"),
          },
        },
      };

      const labels = [];

      function getCovidData() {
        var datList = [];
        for (var i = 0; i < data["Noise"].length; i++) {
          if (data["Noise"][i]["Date"] == selectedDate) {
            datList.push(data["Noise"][i]["Decibles"]);
            labels.push(data["Noise"][i]["Location"]);
          }
        }
        return datList;
      }

      const dataGraph = {
        labels,
        datasets: [
          {
            fill: true,
            label: "Noise in Decibels",
            data: getCovidData(),
            borderColor: ["rgb(53, 162, 235)", "rgba(255, 99, 132)"],
            backgroundColor: [
              "rgba(53, 162, 235, 0.5)",
              "rgba(255, 99, 132, 0.5)",
            ],
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(53, 162, 235)",
            },
          },
        ],
      };
      return (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PolarArea
            style={{ width: "75vw" }}
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
        </div>
      );
    } else if (selectedDisease == "TYPHOID") {
      var options = {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Typhoid data",
          },
        },
      };

      var tempData = Array.from(data["Typhoid-Cholera"]);
      tempData.splice(0, 1);

      const labels = tempData.map((temp) =>
        moment(temp["Typhoid"]).format("DD-MM-YYYY")
      );

      const dataGraph = {
        labels,
        datasets: [
          {
            fill: true,
            label: "Salmonella Presence",
            data: tempData.map((temp) =>
              temp["Unnamed: 1"] == "Absent" ? 0 : 1
            ),
            borderColor: "rgba(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(255, 99, 132)",
            },
          },
        ],
      };
      return (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Bar
            style={{ width: "75vw" }}
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
        </div>
      );
    } else if (selectedDisease == "CHOLERA") {
      var options = {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Cholera data",
          },
        },
      };

      var tempData2 = Array.from(data["Typhoid-Cholera"]);
      tempData2.splice(0, 1);

      const labels = tempData2.map((temp) =>
        moment(temp["Cholera"]).format("DD-MM-YYYY")
      );

      const dataGraph = {
        labels,
        datasets: [
          {
            fill: true,
            label: "Vibro Cholerae Presence",
            data: tempData2.map((temp, index) => {
              return temp["Unnamed: 4"] == "Absent" ? 0 : 1;
            }),
            borderColor: "rgba(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(255, 99, 132)",
            },
          },
        ],
      };
      return (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Bar
            style={{ width: "75vw" }}
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
        </div>
      );
    } else if (selectedDisease == "AQI") {
      var options = {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "AQI data on " + moment(selectedDate).format("DD-MM-YYYY"),
          },
        },
      };

      const labels = [
        "Meadows",
        "North Park",
        "Elysium",
        "Water lily",
        "Aangan",
        "Workers colony",
        "Shopping centre",
      ];

      function get1() {
        var datList = [];
        for (var i = 0; i < data["AQI"].length; i++) {
          if (data["AQI"][i]["Date"] == selectedDate) {
            if (data["AQI"][i]["PM10"] == null) {
              datList.push(0);
            } else {
              datList.push(data["AQI"][i]["PM10"]);
            }
          }
        }
        return datList;
      }
      function get2() {
        var datList = [];
        for (var i = 0; i < data["AQI"].length; i++) {
          if (data["AQI"][i]["Date"] == selectedDate) {
            if (data["AQI"][i]["PM2.5"] == null) {
              datList.push(0);
            } else {
              datList.push(data["AQI"][i]["PM2.5"]);
            }
          }
        }
        return datList;
      }
      function get3() {
        var datList = [];
        for (var i = 0; i < data["AQI"].length; i++) {
          if (data["AQI"][i]["Date"] == selectedDate) {
            if (data["AQI"][i]["SO2"] == null) {
              datList.push(0);
            } else {
              datList.push(data["AQI"][i]["SO2"]);
            }
          }
        }
        return datList;
      }
      function get4() {
        var datList = [];
        for (var i = 0; i < data["AQI"].length; i++) {
          if (data["AQI"][i]["Date"] == selectedDate) {
            if (data["AQI"][i]["NO2"] == null) {
              datList.push(0);
            } else {
              datList.push(data["AQI"][i]["NO2"]);
            }
          }
        }
        return datList;
      }
      function get5() {
        var datList = [];
        for (var i = 0; i < data["AQI"].length; i++) {
          if (data["AQI"][i]["Date"] == selectedDate) {
            if (data["AQI"][i]["CO"] == null) {
              datList.push(0);
            } else {
              datList.push(data["AQI"][i]["CO"]);
            }
          }
        }
        return datList;
      }
      function get6() {
        var datList = [];
        for (var i = 0; i < data["AQI"].length; i++) {
          if (data["AQI"][i]["Date"] == selectedDate) {
            if (data["AQI"][i]["O3"] == null) {
              datList.push(0);
            } else {
              datList.push(data["AQI"][i]["O3"]);
            }
          }
        }
        return datList;
      }
      function get7() {
        var datList = [];
        for (var i = 0; i < data["AQI"].length; i++) {
          if (data["AQI"][i]["Date"] == selectedDate) {
            if (data["AQI"][i]["NH3"] == null) {
              datList.push(0);
            } else {
              datList.push(data["AQI"][i]["NH3"]);
            }
          }
        }
        return datList;
      }
      function get8() {
        var datList = [];
        for (var i = 0; i < data["AQI"].length; i++) {
          if (data["AQI"][i]["Date"] == selectedDate) {
            if (data["AQI"][i]["AQI"] == null) {
              datList.push(0);
            } else {
              datList.push(data["AQI"][i]["AQI"]);
            }
          }
        }
        return datList;
      }

      const dataGraph = {
        labels,
        datasets: [
          {
            fill: false,
            label: "PM10",
            data: get1(),
            borderColor: "rgba(53, 162, 235, 0.5)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(53, 162, 235, 0.5)",
            },
          },
          {
            fill: false,
            label: "PM2.5",
            data: get2(),
            borderColor: "rgba(230, 85, 13, 0.5)",
            backgroundColor: "rgba(230, 85, 13, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(230, 85, 13, 0.5)",
            },
          },
          {
            fill: false,
            label: "SO2",
            data: get3(),
            borderColor: "rgba(222, 45, 38, 0.5)",
            backgroundColor: "rgba(222, 45, 38, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(222, 45, 38, 0.5)",
            },
          },
          {
            fill: false,
            label: "NO2",
            data: get4(),
            borderColor: "rgba(99, 99, 99, 0.5)",
            backgroundColor: "rgba(99, 99, 99, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(99, 99, 99, 0.5)",
            },
          },
          {
            fill: false,
            label: "CO",
            data: get5(),
            borderColor: "rgba(136, 86, 167, 0.5)",
            backgroundColor: "rgba(136, 86, 167,0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(136, 86, 167, 0.5)",
            },
          },
          {
            fill: false,
            label: "O3",
            data: get6(),
            borderColor: "rgba(28, 144, 153, 0.5)",
            backgroundColor: "rgba(28, 144, 153,0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(28, 144, 153, 0.5)",
            },
          },
          {
            fill: false,
            label: "NH3",
            data: get7(),
            borderColor: "rgba(44, 162, 95, 0.5)",
            backgroundColor: "rgba(44, 162, 95,0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(44, 162, 95, 0.5)",
            },
          },
          {
            fill: true,
            label: "AQI",
            data: get8(),
            borderColor: "rgb(12, 44, 132)",
            backgroundColor: "rgba(12, 44, 132,0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgb(12, 44, 132)",
            },
          },
        ],
      };
      return (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Bar
            style={{ width: "75vw" }}
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
        </div>
      );
    } else if (selectedDisease == "WQI") {
      var options = {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "WQI data on " + moment(selectedDate).format("DD-MM-YYYY"),
          },
        },
      };

      const labels = [
        "pH value",
        "Electrical Conductivity (EC)",
        "Total Dissolved Solids (TDS)",
        "Turbidity",
        "Total Alkalinity",
        "Total Hardness",
        "Sodium",
        "Calcium",
        "Magnesium",
        "Sulphate",
        "Chloride",
        "BOD",
        "Silica",
        "Nitrate",
        "Arsenic",
        "Iron",
        "Lead",
        "Mercury",
        "Cadmium",
        "Total coliform (1 Present,0 Absent)",
        "E.coli (1 Present,0 Absent)",
      ];

      function get1() {
        var datList = [];
        for (var i = 0; i < data["WQI"].length; i++) {
          if (data["WQI"][i]["Date"] == selectedDate) {
            if (data["WQI"][i]["Si"] == null) {
              datList.push(0);
            } else {
              datList.push(data["WQI"][i]["Si"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get2() {
        var datList = [];
        for (var i = 0; i < data["WQI"].length; i++) {
          if (data["WQI"][i]["Date"] == selectedDate) {
            if (data["WQI"][i]["Vi"] == null) {
            } else {
              datList.push(data["WQI"][i]["Vi"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get3() {
        var datList = [];
        for (var i = 0; i < data["WQI"].length; i++) {
          if (data["WQI"][i]["Date"] == selectedDate) {
            if (data["WQI"][i]["c"] == null) {
              datList.push(0);
            } else {
              datList.push(data["WQI"][i]["c"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get4() {
        var datList = [];
        for (var i = 0; i < data["WQI"].length; i++) {
          if (data["WQI"][i]["Date"] == selectedDate) {
            if (data["WQI"][i]["wi"] == null) {
              datList.push(0);
            } else {
              datList.push(data["WQI"][i]["wi"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get5() {
        var datList = [];
        for (var i = 0; i < data["WQI"].length; i++) {
          if (data["WQI"][i]["Date"] == selectedDate) {
            if (data["WQI"][i]["Wi"] == null) {
              datList.push(0);
            } else {
              datList.push(data["WQI"][i]["Wi"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get6() {
        var datList = [];
        for (var i = 0; i < data["WQI"].length; i++) {
          if (data["WQI"][i]["Date"] == selectedDate) {
            if (data["WQI"][i]["Qi"] == null) {
              datList.push(0);
            } else {
              datList.push(data["WQI"][i]["Qi"].toFixed(2));
            }
          }
        }
        return datList;
      }
      function get7() {
        var datList = [];
        for (var i = 0; i < data["WQI"].length; i++) {
          if (data["WQI"][i]["Date"] == selectedDate) {
            if (data["WQI"][i]["A"] == null) {
              datList.push(0);
            } else {
              datList.push(data["WQI"][i]["A"].toFixed(2));
            }
          }
        }
        return datList;
      }

      const dataGraph = {
        labels,
        datasets: [
          {
            fill: false,
            label: "Si",
            data: get1(),
            borderColor: "rgba(53, 162, 235, 0.5)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(53, 162, 235, 0.5)",
            },
          },
          {
            fill: false,
            label: "Vi",
            data: get2(),
            borderColor: "rgba(230, 85, 13, 0.5)",
            backgroundColor: "rgba(230, 85, 13, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(230, 85, 13, 0.5)",
            },
          },
          {
            fill: false,
            label: "1/Si",
            data: get3(),
            borderColor: "rgba(222, 45, 38, 0.5)",
            backgroundColor: "rgba(222, 45, 38, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(222, 45, 38, 0.5)",
            },
          },
          {
            fill: false,
            label: "wi",
            data: get4(),
            borderColor: "rgba(99, 99, 99, 0.5)",
            backgroundColor: "rgba(99, 99, 99, 0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(99, 99, 99, 0.5)",
            },
          },
          {
            fill: false,
            label: "Wi",
            data: get5(),
            borderColor: "rgba(136, 86, 167, 0.5)",
            backgroundColor: "rgba(136, 86, 167,0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(136, 86, 167, 0.5)",
            },
          },
          {
            fill: false,
            label: "Qi",
            data: get6(),
            borderColor: "rgba(28, 144, 153, 0.5)",
            backgroundColor: "rgba(28, 144, 153,0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(28, 144, 153, 0.5)",
            },
          },
          {
            fill: false,
            label: "A",
            data: get7(),
            borderColor: "rgba(44, 162, 95, 0.5)",
            backgroundColor: "rgba(44, 162, 95,0.5)",
            datalabels: {
              display: showLables,
              align: "end",
              anchor: "end",
              color: "rgba(44, 162, 95, 0.5)",
            },
          },
        ],
      };
      return (
        <div
          style={{
            height: "75vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Bar
            style={{ width: "75vw" }}
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
        </div>
      );
    }
  }

  if (isLoading) {
    return (
      <Fragment>
        {state.auth.isAuthenticated == true ? <Sidebar /> : ""}
        <div
          style={{
            maxWidth: "88vw",
            marginTop: "45vh",
            marginLeft: "8vw",
            backgroundColor: "white",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {state.auth.isAuthenticated == true ? <Sidebar /> : ""}

        <div style={{ display: "flex" }}>
          <div
            style={{
              // boxShadow:
              //   " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)",
              height: "150px",
              // backgroundColor: "#184e77",
              width: "75%",
              margin: "15px",
              borderRadius: "25px",
              color: "#184e77",
              fontSize: "30px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: "-40px",
              }}
            >
              <span>Shantigram Early Warning System Pilot</span>
              {selectedGroup == "OVERALL" ? (
                <div style={{ display: "flex" }}>
                  <Button
                    style={{ backgroundColor: "#184e77", color: "white" }}
                    onClick={() => setShowLables(!showLables)}
                  >
                    {showLables ? "Hide" : "Show"} Labels
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#184e77",
                      color: "white",
                      marginLeft: "5px",
                    }}
                    onClick={handleResetZoom}
                  >
                    Reset Zoom
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
            <br />
            <div style={{ display: "flex" }}>
              {selectedGroup == "OVERALL" ? (
                <Button
                  fullWidth
                  value={"OVERALL"}
                  onClick={() => setSelectedGroup("OVERALL")}
                  p={2}
                  style={{
                    backgroundColor: "#184e77",
                    color: "white",
                    height: "80px",
                    marginTop: "15px",
                    marginRight: "10px",
                  }}
                  borderRadius={6}
                >
                  <h3
                    style={{
                      marginTop: "21px",
                      fontSize: "20px",
                    }}
                  >
                    OVERALL TOWNSHIP
                  </h3>
                </Button>
              ) : (
                <Button
                  fullWidth
                  value={"OVERALL"}
                  onClick={() => setSelectedGroup("OVERALL")}
                  p={2}
                  style={{
                    backgroundColor: "#d7ecff",
                    color: "white",
                    height: "80px",
                    marginTop: "15px",
                    marginRight: "10px",
                  }}
                  borderRadius={6}
                >
                  <h3
                    style={{
                      marginTop: "21px",
                      fontSize: "20px",
                      color: "#084594",
                    }}
                  >
                    OVERALL TOWNSHIP
                  </h3>
                </Button>
              )}
              {selectedGroup == "CLUSTER" ? (
                <Button
                  fullWidth
                  value={"CLUSTER"}
                  onClick={() => {
                    var tempDates = dateList;
                    tempDates.reverse();
                    setDateList(tempDates);
                    setSelectedGroup("CLUSTER");
                  }}
                  p={2}
                  style={{
                    backgroundColor: "#184e77",
                    color: "white",
                    height: "80px",
                    margin: "10px",
                    marginTop: "15px",
                    marginLeft: "10px",
                  }}
                  borderRadius={6}
                >
                  <h3
                    style={{
                      marginTop: "21px",
                      fontSize: "20px",
                    }}
                  >
                    CLUSTER
                  </h3>
                </Button>
              ) : (
                <Button
                  fullWidth
                  value={"CLUSTER"}
                  onClick={() => {
                    var tempDates = dateList;
                    tempDates.reverse();
                    setDateList(tempDates);
                    setSelectedGroup("CLUSTER");
                  }}
                  p={2}
                  style={{
                    backgroundColor: "#d7ecff",
                    color: "white",
                    height: "80px",
                    margin: "10px",
                    marginTop: "15px",
                    marginLeft: "10px",
                  }}
                  borderRadius={6}
                >
                  <h3
                    style={{
                      marginTop: "21px",
                      fontSize: "20px",
                      color: "#084594",
                    }}
                  >
                    CLUSTER
                  </h3>
                </Button>
              )}
            </div>
          </div>
          <div
            style={{
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)",
              height: "120px",
              backgroundColor: "#184e77",
              width: "25%",
              margin: "15px 15px 0px 0px",
              padding: "20px",
              borderRadius: "25px",
              color: "white",
              fontSize: "20px",
            }}
          >
            {" "}
            Data updated as of:
            {" " + moment(dateList[0]).format("DD-MM-YYYY")}
            <br />
            <div
              style={{
                color: selectedGroup == "OVERALL" ? "white" : "grey",
                fontFamily: "Lato-Regular",
                fontSize: "14px",
                marginTop: "15px",
                backgroundColor: "#184e77",
                padding: "3px",
              }}
            >
              Select Date
            </div>
            <Select
              value={selectedDate}
              disabled={selectedGroup == "OVERALL" ? false : true}
              onChange={(e) => setSelectedDate(e.target.value)}
              style={{
                marginTop: "2px",
                height: "40px",
                marginBottom: "5px",
                backgroundColor: "white",
                // color:"white"
              }}
              fullWidth
              variant={"outlined"}
            >
              {getDates()}
            </Select>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0px 10px -10px 10px",
          }}
        >
          {selectedDisease == "COVID" ? (
            <Button
              fullWidth
              value={"COVID"}
              onClick={() => {}}
              p={2}
              style={{
                backgroundColor: "#184e77",
                color: "white",
                height: "40px",
                margin: "10px",
              }}
              borderRadius={6}
            >
              <h3
                style={{
                  marginTop: "21px",
                  fontSize: "17px",
                }}
              >
                COVID-19
              </h3>
            </Button>
          ) : (
            <Button
              fullWidth
              value={"COVID"}
              onClick={() => handleTabChange("COVID")}
              p={2}
              style={{
                backgroundColor: "#d7ecff",
                color: "white",
                height: "40px",
                margin: "10px",
              }}
              borderRadius={6}
            >
              <h3
                style={{
                  marginTop: "21px",
                  fontSize: "17px",
                  color: "#084594",
                }}
              >
                COVID-19
              </h3>
            </Button>
          )}
          {selectedDisease == "DENGUE" ? (
            <Button
              fullWidth
              value={"DENGUE"}
              onClick={() => {}}
              p={2}
              style={{
                backgroundColor: "#184e77",
                color: "white",
                height: "40px",
                margin: "10px",
              }}
              borderRadius={6}
            >
              <h3
                style={{
                  marginTop: "21px",
                  fontSize: "17px",
                }}
              >
                DENGUE
              </h3>
            </Button>
          ) : (
            <Button
              fullWidth
              value={"DENGUE"}
              onClick={() => handleTabChange("DENGUE")}
              p={2}
              style={{
                backgroundColor: "#d7ecff",
                color: "white",
                height: "40px",
                margin: "10px",
              }}
              borderRadius={6}
            >
              <h3
                style={{
                  marginTop: "21px",
                  fontSize: "17px",
                  color: "#084594",
                }}
              >
                DENGUE
              </h3>
            </Button>
          )}
          {selectedDisease == "MALARIA" ? (
            <Button
              fullWidth
              value={"MALARIA"}
              onClick={() => {}}
              p={2}
              style={{
                backgroundColor: "#184e77",
                color: "white",
                height: "40px",
                margin: "10px",
              }}
              borderRadius={6}
            >
              <h3
                style={{
                  marginTop: "21px",
                  fontSize: "17px",
                }}
              >
                MALARIA
              </h3>
            </Button>
          ) : (
            <Button
              fullWidth
              value={"MALARIA"}
              onClick={() => handleTabChange("MALARIA")}
              p={2}
              style={{
                backgroundColor: "#d7ecff",
                color: "white",
                height: "40px",
                margin: "10px",
              }}
              borderRadius={6}
            >
              <h3
                style={{
                  marginTop: "21px",
                  fontSize: "17px",
                  color: "#084594",
                }}
              >
                MALARIA
              </h3>
            </Button>
          )}
          {selectedDisease == "NOISE" ? (
            <Button
              fullWidth
              value={"NOISE"}
              onClick={() => {}}
              p={2}
              style={{
                backgroundColor: "#184e77",
                color: "white",
                height: "40px",
                margin: "10px",
              }}
              borderRadius={6}
            >
              <h3
                style={{
                  marginTop: "21px",
                  fontSize: "17px",
                }}
              >
                NOISE
              </h3>
            </Button>
          ) : (
            <Button
              fullWidth
              value={"NOISE"}
              onClick={() => handleTabChange("NOISE")}
              p={2}
              style={{
                backgroundColor: "#d7ecff",
                color: "white",
                height: "40px",
                margin: "10px",
              }}
              borderRadius={6}
            >
              <h3
                style={{
                  marginTop: "21px",
                  fontSize: "17px",
                  color: "#084594",
                }}
              >
                NOISE
              </h3>
            </Button>
          )}
          {selectedDisease == "TYPHOID" ? (
            <Button
              fullWidth
              value={"TYPHOID"}
              onClick={() => setSelectedDisease("TYPHOID")}
              p={2}
              style={{
                backgroundColor: "#184e77",
                color: "white",
                height: "40px",
                margin: "10px",
              }}
              borderRadius={6}
            >
              <h3
                style={{
                  marginTop: "21px",
                  fontSize: "17px",
                }}
              >
                TYPHOID
              </h3>
            </Button>
          ) : (
            <Button
              fullWidth
              value={"TYPHOID"}
              onClick={() => setSelectedDisease("TYPHOID")}
              p={2}
              style={{
                backgroundColor: "#d7ecff",
                color: "white",
                height: "40px",
                margin: "10px",
              }}
              borderRadius={6}
            >
              <h3
                style={{
                  marginTop: "21px",
                  fontSize: "17px",
                  color: "#084594",
                }}
              >
                TYPHOID
              </h3>
            </Button>
          )}
          {selectedDisease == "CHOLERA" ? (
            <Button
              fullWidth
              value={"CHOLERA"}
              onClick={() => setSelectedDisease("CHOLERA")}
              p={2}
              style={{
                backgroundColor: "#184e77",
                color: "white",
                height: "40px",
                margin: "10px",
              }}
              borderRadius={6}
            >
              <h3
                style={{
                  marginTop: "21px",
                  fontSize: "17px",
                }}
              >
                CHOLERA
              </h3>
            </Button>
          ) : (
            <Button
              fullWidth
              value={"CHOLERA"}
              onClick={() => setSelectedDisease("CHOLERA")}
              p={2}
              style={{
                backgroundColor: "#d7ecff",
                color: "white",
                height: "40px",
                margin: "10px",
              }}
              borderRadius={6}
            >
              <h3
                style={{
                  marginTop: "21px",
                  fontSize: "17px",
                  color: "#084594",
                }}
              >
                CHOLERA
              </h3>
            </Button>
          )}
          {selectedDisease == "AQI" ? (
            <Button
              fullWidth
              value={"AQI"}
              onClick={() => {}}
              p={2}
              style={{
                backgroundColor: "#184e77",
                color: "white",
                height: "40px",
                margin: "10px",
              }}
              borderRadius={6}
            >
              <h3
                style={{
                  marginTop: "21px",
                  fontSize: "17px",
                }}
              >
                AQI
              </h3>
            </Button>
          ) : (
            <Button
              fullWidth
              value={"AQI"}
              onClick={() => handleTabChange("AQI")}
              p={2}
              style={{
                backgroundColor: "#d7ecff",
                color: "white",
                height: "40px",
                margin: "10px",
              }}
              borderRadius={6}
            >
              <h3
                style={{
                  marginTop: "21px",
                  fontSize: "17px",
                  color: "#084594",
                }}
              >
                AQI
              </h3>
            </Button>
          )}
          {selectedDisease == "WQI" ? (
            <Button
              fullWidth
              value={"WQI"}
              onClick={() => {}}
              p={2}
              style={{
                backgroundColor: "#184e77",
                color: "white",
                height: "40px",
                margin: "10px",
              }}
              borderRadius={6}
            >
              <h3
                style={{
                  marginTop: "21px",
                  fontSize: "17px",
                }}
              >
                WQI
              </h3>
            </Button>
          ) : (
            <Button
              fullWidth
              value={"WQI"}
              onClick={() => handleTabChange("WQI")}
              p={2}
              style={{
                backgroundColor: "#d7ecff",
                color: "white",
                height: "40px",
                margin: "10px",
              }}
              borderRadius={6}
            >
              <h3
                style={{
                  marginTop: "21px",
                  fontSize: "17px",
                  color: "#084594",
                }}
              >
                WQI
              </h3>
            </Button>
          )}
        </div>
        {selectedGroup == "OVERALL" ? getOverallContent() : getContent()}
      </Fragment>
    );
  }
}

export default Dashboard;
